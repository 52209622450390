<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import SlLoading from "components/layout/components/Loading.vue"
import Resumo from "components/leiloes/components/dashboard/Resumo.vue"
import UltimosLances from "components/leiloes/components/dashboard/UltimosLances.vue"
import RequerAtencao from "components/leiloes/components/dashboard/RequerAtencao.vue"
import EstatisticaLeilao from "components/leiloes/components/dashboard/EstatisticaLeilao.vue"
import EstatisticaLotes from "components/leiloes/components/dashboard/EstatisticaLotes.vue"
import DocumentosLeilao from "components/leiloes/components/dashboard/Documentos.vue"
import ResumoHabilitacoes from "components/leiloes/components/dashboard/EstatisticaHabilitacoes.vue";
import ResumoMarketplace from "components/leiloes/components/dashboard/ResumoMarketplace.vue";

export default {
  name: 'Leilao',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {
    ResumoMarketplace,
    ResumoHabilitacoes,
    DocumentosLeilao,
    EstatisticaLotes,
    EstatisticaLeilao,
    RequerAtencao,
    UltimosLances,
    Resumo,
    SlLoading
  },
  computed: {
  },
  mounted () {
    this.erplayout && this.erplayout.erpheader.setMenuModuleToggle(true)
    if (this.page && this.page.leilao) {
      this.$options.meta.title = `Leilão ${this.page.leilao.codigo}`
    }
  },
  destroyed () {
  },
  methods: {
  },
  meta: {
    title: 'Leilão',
    name: 'Leilão'
  }
}
</script>

<template>
  <div style="margin: auto; max-width: 1440px">
    <div class="ev2-body leilao-dash">
      <div class="content-grid">
          <resumo />
          <ultimos-lances />
          <requer-atencao />
          <estatistica-leilao />
          <estatistica-lotes />
          <documentos-leilao />
          <resumo-habilitacoes />
          <resumo-marketplace />
      </div>
    </div>
  </div>
</template>
