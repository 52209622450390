<template>
  <div class="ev2-box dash-ultimos-lances">
    <div class="header">
      <div class="hcol1">
        <div class="title">Últimos lances</div>
        <div class="subtitle">Histórico mais recente</div>
      </div>
      <div class="hcol2">
        <i class="fal fa-refresh cursor-pointer" @click="load" v-if="!table.loading" />
        <sl-loading v-else class="size-18" content="" />
      </div>
    </div>
    <div class="body">
      <u-table
          ref="table"
          color="primary"
          :data="table.serverData"
          :columns="table.columns"
          :visible-columns="visibledColumns"
          :filter="table.busca"
          row-key="id"
          :pagination.sync="table.serverPagination"
          @request="request"
          selection="multiple"
          :selected.sync="table.selected"
          :loading="table.loading"
          class="ev2-dash-table time-lista"
          :rows-per-page-options="[20, 50, 100]"
          :hide-no-results-label="true"
      >
        <u-tr slot="header" slot-scope="props">
          <u-th v-for="col in props.cols" :key="col.name" :props="props">
            <template>
              {{ col.label }}
            </template>
            <div v-if="col.name === 'options'" class="text-center">
            </div>
          </u-th>
        </u-tr>

        <u-tr class="cursor-pointer" @click.native="lanceWindow(props.row)" slot="body" slot-scope="props" :props="props">
          <u-td class="td-limit" key="person" :props="props">
            <div class="flex" style="flex-wrap: nowrap">
              <div class="app-person-avatar m-r-sm flex justify-center full-width">
                <img v-if="props.row.arrematante.pessoa && props.row.arrematante.pessoa.photo" :src="props.row.arrematante.pessoa.photo">
                <div v-else class="img-fake">{{ firstLettersName(props.row.arrematante.pessoa.name) }}</div>
              </div>
              <div class="flex items-center m-l-sm m-r-xs">
                <div>
                  <div class="font-bold font-10 m-b-xs">{{firstname(props.row.arrematante.pessoa.name)}}</div>
                  <div class="font-10 text-grey">{{props.row.autor.apelido}}</div>
                  <u-tooltip :offset="[15,15]">{{props.row.arrematante.pessoa.name}}</u-tooltip>
                </div>
              </div>
            </div>
          </u-td>
          <u-td key="lote" :props="props">
            <div @click.stop.prevent="loteWindow(props.row.lote)" class="m-r-xs lote-n">{{props.row.lote.numeroString || props.row.lote.numero}}</div>
          </u-td>
          <u-td key="lance" :props="props">
            R$ {{props.row.valor|moeda}}
          </u-td>
        </u-tr>
      </u-table>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"
import {UTable, UTr, UTh, UTd, date, UPopover, UTooltip} from "uloc-vue"
import tableColumnsCache from "@/utils/tableColumnsCache"
import {list} from "@/domain/leiloes/services/lances"
import lanceWindow from "components/leiloes/windows/lance"
import loteWindow from "components/leiloes/windows/lote"
import SlLoading from "components/layout/components/Loading.vue";
const listName = 'table.leilao.dash.lances'
const listStorage = tableColumnsCache(listName, [
  {label: 'Pessoa', name: 'person', active: true, sortable: false, ordering: 1},
  {label: 'Lote', name: 'lote', active: true, sortable: false, ordering: 2},
  {label: 'Lance', name: 'lance', active: true, sortable: false, ordering: 3},
], 1)

export default {
  name: "UltimosLances",
  data () {
    return {
      listStorage: listStorage,
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 0, // specifying this determines pagination is server-side
          rowsPerPage: 5
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: {},
        selected: [],
        loading: false
      }
    }
  },
  mounted() {
    this.load()
  },
  components: {
    SlLoading,
    UTable,
    UTr,
    UTh,
    UTd,
    UTooltip
    // UPopover
  },
  mixins: [tabMixin],
  computed: {
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  methods: {
    load () {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca
      })
    },
    lanceWindow,
    loteWindow,
    request({pagination, filter}) {
      this.table.loading = true
      let _filter = this.filtros
      const extraFilters = []
      extraFilters.push(`&leilao=${this.leilao.id}`)
      let filtros = encodeURI(`&sortBy=${pagination.sortBy || 'createdAt'}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}${extraFilters.join('')}`)
      list(pagination.rowsPerPage, pagination.page, filtros)
          .then((response) => {
            const data = response.data
            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
            this.table.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    }
  }
}
</script>
